#root,
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    border: 0;
}

body {
    background-color: #202020;
}

.titlebar {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 40px;
    -webkit-app-region: drag;
    -webkit-user-select: none;
    user-select: none;
}